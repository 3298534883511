export default {
    menus() {
        return [
            {
                label: '首页',
                pages: [
                    { label: '欢迎使用后台系统', path: 'home' },
                ]
            }, {
                label: '采购',
                pages: [
                    { label: '采购合同', path: 'procureContract' },
                    { hidden: true, label: '采购合同详情', path: 'procureContractDetail' },
                    { hidden: true, label: '采购合同审核', path: 'procureContractReview' },
                    { label: '申请付款', path: 'procurePay' },
                    { hidden: true, label: '申请付款详情', path: 'procurePayDetail' },
                    { hidden: true, label: '申请付款审核', path: 'procurePayReview' },
                    { label: '发票信息', path: 'procureInvoice' },
                    { hidden: true, label: '发票信息详情', path: 'procureInvoiceDetail' },
                    { label: '采购明细（发车）', path: 'procureSend' },
                    { hidden: true, label: '采购明细（发车）详情', path: 'procureSendDetail' },
                    { label: '采购日汇总（发车）', path: 'procureDailySupplier' },
                    { label: '采购月明细', path: 'procureMonthly' },
                    { label: '合同执行日报', path: 'procureReportMonthly' },
                    { label: '合同执行日报汇总', path: 'procureReportDaily' },
                    { label: '采购月报表', path: 'procureReportYearly' },
                ]
            }, {
                label: '上游运输',
                pages: [
                    { label: '运输合同', path: 'transportContract' },
                    { hidden: true, label: '运输合同详情', path: 'transportContractDetail' },
                    { hidden: true, label: '运输合同审核', path: 'transportContractReview' },
                    { label: '申请付款', path: 'transportPay' },
                    { hidden: true, label: '申请付款详情', path: 'transportPayDetail' },
                    { hidden: true, label: '申请付款审核', path: 'transportPayReview' },
                    { label: '发票信息', path: 'transportInvoice' },
                    { hidden: true, label: '发票信息详情', path: 'transportInvoiceDetail' },
                    { label: '运费往来', path: 'transportPayAndInvoice' },
                    { label: '运费校对', path: 'upstreamTransportPriceCheck' },
                ]
            }, {
                label: '仓储',
                pages: [
                    { label: '仓储合同', path: 'storageContract' },
                    { hidden: true, label: '仓储合同详情', path: 'storageContractDetail' },
                    { hidden: true, label: '仓储合同审核', path: 'storageContractReview' },
                    { label: '申请付款', path: 'storagePay' },
                    { hidden: true, label: '申请付款详情', path: 'storagePayDetail' },
                    { hidden: true, label: '申请付款审核', path: 'storagePayReview' },
                    { label: '采购明细（到达）', path: 'procure' },
                    { label: '采购日汇总（到达）', path: 'procureDailySupplier2' },
                    { hidden: true, label: '采购明细详情', path: 'procureDetail' },
                    { label: '销售明细（发车）', path: 'saleSend' },
                    { hidden: true, label: '销售明细（发车）详情', path: 'saleSendDetail' },
                    { label: '销售明细（发车）后台', path: 'saleSendBack' },
                    { hidden: true, label: '销售明细（发车）详情', path: 'saleSendDetailBack' },
                    { label: '销售日汇总', path: 'saleDaily' },
                    { label: '库存及成本查询', path: 'storageInventory' },
                    { label: '库存成本明细查询', path: 'storageInventoryDetail' },
                    { label: '库存日成本表', path: 'storageInventoryDaily' },
                    { label: '仓储取样', path: 'storageSample' },
                    { hidden: true, label: '仓储取样详情', path: 'storageSampleDetail' },
                    { label: '库存月报表', path: 'inventoryReportYearly' },
                ]
            }, {
                label: '下游运输',
                pages: [
                    { label: '运输合同', path: 'transportContract' },
                    { hidden: true, label: '运输合同详情', path: 'transportContractDetail' },
                    { hidden: true, label: '运输合同审核', path: 'transportContractReview' },
                    { label: '申请付款', path: 'transportPay' },
                    { hidden: true, label: '申请付款详情', path: 'transportPayDetail' },
                    { hidden: true, label: '申请付款审核', path: 'transportPayReview' },
                    { label: '发票信息', path: 'transportInvoice' },
                    { hidden: true, label: '发票信息详情', path: 'transportInvoiceDetail' },
                    { label: '运费往来', path: 'transportPayAndInvoice' },
                    { label: '运费校对', path: 'downstreamTransportPriceCheck' },
                ]
            }, {
                label: '销售',
                pages: [
                    { label: '销售合同', path: 'saleContract' },
                    { hidden: true, label: '销售合同详情', path: 'saleContractDetail' },
                    { hidden: true, label: '销售合同审核', path: 'saleContractReview' },
                    { label: '销售发票', path: 'saleInvoice' },
                    { hidden: true, label: '销售发票详情', path: 'saleInvoiceDetail' },
                    { label: '销售收款', path: 'salePay' },
                    { label: '销售明细（到达）', path: 'sale' },
                    { hidden: true, label: '销售明细（到达）详情', path: 'saleDetailReceive' },
                    { label: '销售明细（到达）后台', path: 'saleBack' },
                    { hidden: true, label: '销售明细（到达）详情', path: 'saleDetail' },
                    { label: '销售取样', path: 'saleSample' },
                    { hidden: true, label: '销售取样详情', path: 'saleSampleDetail' },
                    { label: '销售数量校对', path: 'saleSampleCheck' },
                    { label: '销售结算', path: 'saleSampleSettlement' },
                    { label: '销售结算单', path: 'saleSettlement' },
                    // { label: '销售结算按日自由汇总', path: 'saleSampleSettlementDaily' },
                    { label: '结算月报表', path: 'saleReportYearly' },
                    { label: '未出结算单吨数指标', path: 'saleSampleUnsettlement' },
                    { label: '客户待出指标', path: 'saleUnsampleDaily' },
                    { label: '仓储到货量统计', path: 'saleReceiveQuantityStorage' },
                    { label: '客户到货量统计', path: 'saleReceiveQuantity' },
                    { label: '已结算数量统计', path: 'saleSampleSettlementQuantity' },
                    { label: '指标分解情况', path: 'saleSampleSampleDaily' },
                ]
            }, {
                label: '利润',
                pages: [
                    { label: '精细利润核算', path: 'saleProfit' },
                    { label: '利润月报表', path: 'saleProfitReportYearly' },
                    { label: '税金月报表', path: 'taxReportYearly' },
                ]
            }, {
                label: '系统管理',
                pages: [
                    { label: '数据字典', path: 'dict' },
                    { label: '销售品名转比例设置', path: 'customNoteToMix' },
                    { hidden: true, label: '销售品名转比例详情', path: 'customNoteToMixDetail' },
                    { label: '公式设置', path: 'formula' },
                    { hidden: true, label: '公式设置详情', path: 'formulaDetail' },
                    { label: '个人中心', path: 'my' },
                    { label: '权限管理', path: 'roleList' },
                    { hidden: true, label: '权限详情', path: 'roleDetail' },
                    { label: '后台用户', path: 'userList' },
                    { hidden: true, label: '后台用户详情', path: 'userDetail' },
                ]
            }
        ]
    },
    filter(hidden, paths) {
        const menus = this.menus()
        for (let menuIndex = menus.length - 1; menuIndex >= 0; menuIndex--) {
            const menu = menus[menuIndex]
            if (menu != null && menu.pages != null) {
                for (let pageIndex = menu.pages.length - 1; pageIndex >= 0; pageIndex--) {
                    let splice = false
                    const page = menu.pages[pageIndex]
                    if (hidden === true && !page.hidden) {
                        splice = true
                    }
                    if (hidden === false && page.hidden) {
                        splice = true
                    }
                    if (paths != null && paths.length > 0 && !paths.includes(page.path)) {
                        splice = true
                    }
                    if (splice) {
                        menu.pages.splice(pageIndex, 1)
                    }
                }
            }
            if (menu == null || menu.pages == null || menu.pages.length == 0) {
                menus.splice(menuIndex, 1)
            }
        }
        return menus
    }
}