import { createRouter, createWebHashHistory } from 'vue-router'

import menusConf from './menusConf'
const menus = menusConf.menus()

const containerPages = []
for (let menusIndex in menus) {
  if (menus[menusIndex] && menus[menusIndex].pages) {
    for (let pageIndex in menus[menusIndex].pages) {
      const page = menus[menusIndex].pages[pageIndex]
      containerPages.push({
        meta: {
          title: page.label
        },
        name: page.path,
        path: page.path,
        component: () => import('../views/pages/' + page.path + '.vue')
      })
    }
  }
}

const routes = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      name: 'container',
      path: '/',
      redirect: 'home',
      component: () => import('../views/container.vue'),
      children: containerPages
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('../views/login.vue'),
    }
  ]
})

routes.beforeEach(async route => {
  document.title = route.meta.title || '欢迎使用后台管理'
})

export default routes